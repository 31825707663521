import React from "react"
import PropTypes from "prop-types"
import * as FontIcon from "react-icons/io5"

// Transform: lock-open -> IoLockOpen
const transformToIoIcon = input => {
  return "Io" + input.replace(/(?:^|-)(\w)/g, (_, c) => c.toUpperCase())
}

const IonIcon = ({ name, style, className }) => {
  if (name) {
    const Icon = FontIcon[transformToIoIcon(name)]
    if (Icon) {
      return <Icon className={className || "icon"} style={style} />
    }
  }
  return <div />
}

IonIcon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}

IonIcon.defaultProps = {
  name: null,
  className: null,
}

export default IonIcon
